import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Lottie from "react-lottie";

const LottieAnimation = ({ animationData, size, message }) => {
  return (
    <Grid
      container
      sx={{
        justifyContent: "center",
        flexDirection: "column !important",
        display: "flex",
        alignItems: "center",
        paddingBottom: "20px;",
        height: "80vh",
      }}
    >
      <Grid
        sx={{
          width: size || "200px;",
        }}
      >
        <Lottie
          options={{
            animationData: animationData,
            autoplay: true,
            loop: true,
            rendererSettings: {
              preserveAspectRadio: "xMidYMid slice",
            },
          }}
        />
      </Grid>

      {message && (
        <Grid item>
          <Typography sx={{ fontStyle: "italic" }}>{message}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
LottieAnimation.propTypes = {
  animationData: PropTypes.object.isRequired,
  size: PropTypes.string,
  message: PropTypes.string,
};
export default LottieAnimation;
