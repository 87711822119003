import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { server } from "src/coreConfig";
import { setEnvironmentState } from "src/slices/environment";
import { useDispatch, useSelector } from "src/store";
import axiosInstance from "src/utils/axios";
import { handleDownloadFile } from "src/utils/dataRenders";

const urls = {
  environment_request: {
    list: `${server}/api/v2/environment/environment_request/list/`,
    new: `${server}/api/v2/environment/environment_request/new/`,
    get: `${server}/api/v2/environment/environment_request/get/`,
    update: `${server}/api/v2/environment/environment_request/update/`,
    delete: `${server}/api/v2/environment/environment_request/delete/`,
    addFile: `${server}/api/v2/environment/environment_request/addFile/`,
    getFile: `${server}/api/v2/environment/environment_request/getFile/`,
    deleteFile: `${server}/api/v2/environment/environment_request/deleteFile/`,
  },
  handle: `${server}/api/v2/environment/handle/`,
};
const useEnvironmentRequest = (currentCustomer, adminMode) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);

  const [environmentRequestState, setEnvironmentRequestState] = useState({
    environmentProducts: null,
    currentEnvironmentRequest: null,
    environmentRequests: null,
    searchResults: null,
    isSearching: false,
    isLoading: false,
    isDeleted: false,
    errorMessages: [],
  });

  const { currentEnvironmentRequest, environmentRequests } = useSelector(
    (state) => state.environment
  );
  const [res, setRes] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const api = async (url, data, options, label) => {
    var _res = loadData(url, data, options, label);
    return _res;
  };

  const requestData = async (url, data, options, label, customMessage) => {
    var _res = await loadData(url, data, options, label, customMessage);
    console.log("requestData", _res);
    if (_res.data?.res === 1) {
      setRes({ ..._res.data, status: _res.status });
    } else {
      setRes(_res);
    }
  };

  const loadData = async (url, data, options, label, customMessage) => {
    if (isLoading) return;
    setIsLoading(true);
    if (label) {
      var sb = enqueueSnackbar(customMessage || t("Obteniendo") + " " + label, {
        variant: "info",
      });
    }
    try {
      var res = await axiosInstance.post(url, data, options);
      if (label) closeSnackbar(sb);
      setIsLoading(false);
      if (options && options.responseType === "blob") {
        return res;
      }
      if (res.data.res === 1) {
        if (label) {
          enqueueSnackbar(label + " " + t("cargado exitosamente."), {
            variant: "success",
          });
        }
        return res;
      } else {
        setIsDataError(true);

        enqueueSnackbar(
          res.data.message || res.data.error || `${label} : error`,
          {
            variant: "error",
          }
        );
        return res;
      }
    } catch (e) {
      console.log(e);
      if (options && options.responseType === "blob") {
        var _d = await e.data.text();
        var _data = JSON.parse(_d);
        enqueueSnackbar(_data.message || `${label} : error`, {
          variant: "error",
        });
        setIsDataError(true);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setIsDataError(true);
      enqueueSnackbar(
        (e.data && e.data.message) ||
          e.message ||
          e.statusText ||
          `${label} : error`,
        { variant: "error" }
      );
      return e;
    }
  };
  const adminListEnvironmentRequests = async (data) => {
    return listEnvironmentRequests(data);
    setIsLoading(true);
    var res = await api(
      urls.handle,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        instance: "request",
        action: "list",
        ...data,
      },
      {},
      null
    );
    setIsLoading(false);
    if (res && res.data.res === 1) {
      dispatch(
        setEnvironmentState({ environmentRequests: res.data.data || [] })
      );
    }
  };

  const newEnvironmentRequest = async (data) => {
    return await api(
      urls.environment_request.new,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        ...data,
      },
      {},
      t("Creando solicitud")
    );
  };

  const loadProducts = async () => {
    var res = await api(
      urls.handle,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        instance: "product",
        action: "list",
      },
      {},
      null
    );
    dispatch(setEnvironmentState({ environmentProducts: res.data.data || [] }));
  };

  const loadUserAddresses = async (ownerId) => {
    // var res = await api(arkadu.urls);
  };

  const getEnvironmentRequests = async (id) => {
    setEnvironmentRequestState((prev) => ({
      ...prev,
      errorMessages: [],
      isLoading: true,
    }));
    var res = await api(
      urls.environment_request.get,
      {
        id: id,
        instance: "request",
        action: "detail",
        mode: adminMode ? "admin" : "user",
        customer_id: currentCustomer.id,
      },
      {},
      t("Cargando detalle solicitud")
    );
    setEnvironmentRequestState((prev) => ({
      ...prev,
      isLoading: false,
    }));

    if (res.data.res === 1) {
      dispatch(
        setEnvironmentState({ currentEnvironmentRequest: res.data.data || [] })
      );
      loadUserAddresses(res.data.data.owner_id);
    } else {
      dispatch(setEnvironmentState({ currentEnvironmentRequest: null }));
      setEnvironmentRequestState((prev) => ({
        ...prev,
        errorMessages: prev.errorMessages.concat(
          res.status === 404 ? t("Solicitud no encontrada") : res.data.message
        ),
      }));
    }
  };

  const listEnvironmentRequests = async (data) => {
    if (!currentCustomer || isLoading) return;
    setIsLoading(true);
    try {
      var res = await api(
        urls.environment_request.list,
        {
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
          page_size: 50,
          page: 1,
          ...data,
        },
        {},
        null
      );
      setIsLoading(false);
      if (res.data.res === 1) {
        dispatch(
          setEnvironmentState({
            environmentRequests: res.data.data || [],
            environmentRequestsCount: res.data.count || 0,
          })
        );
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e.message, {
        variant: "error",
      });
    }
  };

  const searchEnvironmentRequests = async (data) => {
    var res = await api(
      urls.environment_request.list,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        page_size: 50,
        page: 1,
        ...data,
      },
      {},
      t("Cargando solicitudes")
    );
    if (res.data.res === 1) {
      dispatch(setEnvironmentState({ searchResults: res.data.data || [] }));
    }
  };

  const clearSearchResults = async () => {
    dispatch(setEnvironmentState({ searchResults: null }));
  };

  const updateEnvironmentRequest = async (data) => {
    setEnvironmentRequestState((prev) => ({
      ...prev,
      errorMessages: [],
      isLoading: true,
    }));

    var res = await api(
      urls.environment_request.update,
      {
        id: currentEnvironmentRequest.id,
        area: currentEnvironmentRequest.area,
        phone: currentEnvironmentRequest.phone,
        address_id: currentEnvironmentRequest.address_id,
        description: currentEnvironmentRequest.description,
        ...data,
        instance: "request",
        action: "update",
        mode: adminMode ? "admin" : "user",
        customer_id: currentCustomer.id,
      },
      {},
      t("Actualizando solicitud")
    );
    setEnvironmentRequestState((prev) => ({
      ...prev,
      isLoading: false,
    }));
    if (res.data.res === 1) {
      dispatch(
        setEnvironmentState({ currentEnvironmentRequest: res.data.data || [] })
      );
    } else {
      setEnvironmentRequestState((prev) => ({
        ...prev,
        errorMessages: prev.errorMessages.concat(res.data.message),
      }));
    }
  };

  const deleteEnvironmentRequest = async () => {
    if (!currentEnvironmentRequest) return;
    if (!currentEnvironmentRequest.state == "draft") {
      enqueueSnackbar(
        t("No se puede eliminar una solicitud en estado diferente a borrador"),
        { variant: "error" }
      );
      return;
    }

    var res = await api(
      urls.environment_request.delete,
      {
        id: currentEnvironmentRequest.id,
        instance: "request",
        action: "delete",
        mode: adminMode ? "admin" : "user",
        customer_id: currentCustomer.id,
      },
      {},
      t("Eliminando solicitud")
    );
    if (res.data.res === 1) {
      enqueueSnackbar(t("Solicitud eliminada exitosamente"), {
        variant: "success",
      });
      setEnvironmentRequestState((prev) => ({
        ...prev,
        isDeleted: true,
      }));
      dispatch(setEnvironmentState({ currentEnvironmentRequest: null }));
    }
  };

  const addRequestFile = async (data) => {
    // build form data
    setEnvironmentRequestState((prev) => ({
      ...prev,
      errorMessages: [],
      isLoading: true,
    }));

    var formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    formData.append("id", currentEnvironmentRequest.id);
    formData.append("mode", adminMode ? "admin" : "user");
    formData.append("customer_id", currentCustomer.id);
    try {
      var res = await axiosInstance.post(
        urls.environment_request.addFile,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setEnvironmentRequestState((prev) => ({
        ...prev,
        isLoading: false,
      }));

      if (res.data.res === 1) {
        enqueueSnackbar(t("Archivo agregado exitosamente"), {
          variant: "success",
        });
        dispatch(
          setEnvironmentState({
            currentEnvironmentRequest: res.data.data || [],
          })
        );
      } else {
        enqueueSnackbar(res.data.message, {
          variant: "error",
        });
        setEnvironmentRequestState((prev) => ({
          ...prev,
          errorMessages: prev.errorMessages.concat(res.data.message),
        }));
      }
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: "error",
      });
      setEnvironmentRequestState((prev) => ({
        ...prev,
        errorMessages: prev.errorMessages.concat(e.message),
        isLoading: false,
      }));
    }
  };

  const getRequestFile = async (fileId) => {
    var res = await api(
      urls.environment_request.getFile,
      {
        id: fileId,
        mode: adminMode ? "admin" : "user",
        customer_id: currentCustomer.id,
      },
      { responseType: "blob" },
      t("Obteniendo archivo")
    );
    handleDownloadFile(res);
  };

  const deleteRequestFile = async (fileId) => {
    setEnvironmentRequestState((prev) => ({
      ...prev,
      errorMessages: [],
      isLoading: true,
    }));

    var res = await api(
      urls.environment_request.deleteFile,
      {
        id: fileId,
        mode: adminMode ? "admin" : "user",
        customer_id: currentCustomer.id,
      },
      {},
      t("Eliminando archivo")
    );
    setEnvironmentRequestState((prev) => ({
      ...prev,
      isLoading: false,
    }));

    if (res.data.res === 1) {
      enqueueSnackbar(t("Archivo eliminado exitosamente"), {
        variant: "success",
      });
      dispatch(
        setEnvironmentState({ currentEnvironmentRequest: res.data.data || [] })
      );
    } else {
      enqueueSnackbar(res.data.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (currentCustomer) {
      listEnvironmentRequests();
    }
  }, [currentCustomer]);

  return {
    res,
    requestData,
    isLoading,
    api,
    newEnvironmentRequest,
    loadProducts,
    getEnvironmentRequests,
    listEnvironmentRequests,
    searchEnvironmentRequests,
    clearSearchResults,
    updateEnvironmentRequest,
    deleteEnvironmentRequest,
    environmentRequestState,
    addRequestFile,
    getRequestFile,
    deleteRequestFile,
    adminListEnvironmentRequests,
  };
};

export default useEnvironmentRequest;

export { urls as environmentUrls };
