import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import arkadu from "src/coreConfig";
import { setProcedureState } from "src/slices/procedures";
import { useDispatch, useSelector } from "src/store";
import axiosInstance from "src/utils/axios";

const CorporateLayout = ({ children }) => {
  const { proceduresLoaded } = useSelector((state) => state.procedures);
  const dispatch = useDispatch();
  const initialized = useRef(null);
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const loadData = () => {
    axiosInstance
      .post(arkadu.urls.v2.corporate.procedure, {
        action: "",
        mode: adminMode ? "admin" : "user",
        customer_id: currentCustomer.id,
      })
      .then((response) => {
        if (response.data.res === 1) {
          dispatch(
            setProcedureState({
              procedures: response.data.data.procedures,
              departments: response.data.data.departments,
              procedureStates: response.data.data.states,
              proceduresLoaded: true,
            })
          );
          // dispatch(
          //   updateFilters({
          //     state: {
          //       value: response.data.data.states[0].id,
          //       label: response.data.data.states[0].name,
          //     },
          //   })
          // );
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(t("something was wrong"), { variant: "error" });
      });
  };
  useEffect(() => {
    if (initialized.current === null) {
      initialized.current = true;
    }
  }, []);

  useEffect(() => {
    if (!proceduresLoaded && currentCustomer) {
      loadData();
    }
  }, [proceduresLoaded, currentCustomer]);

  if (!proceduresLoaded) {
    return <LoadingScreen />;
  }
  return <div>{children}</div>;
};
export default CorporateLayout;
