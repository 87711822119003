/* eslint-disable no-use-before-define */
import {
  AccountBalanceRounded,
  AccountBalanceWalletRounded,
  AlternateEmailRounded,
  ArchiveRounded,
  AssignmentIndRounded,
  AssignmentRounded,
  AtmRounded,
  BarChartRounded,
  BuildCircleRounded,
  BuildRounded,
  CommuteRounded,
  ContactSupportRounded,
  DomainRounded,
  EmailRounded,
  EmojiTransportationRounded,
  EnergySavingsLeafRounded,
  Folder,
  GradientOutlined,
  GroupWork,
  GroupWorkRounded,
  Home,
  HomeRounded,
  HowToVoteRounded,
  InputRounded,
  LibraryBooks,
  ListAltRounded,
  LocalShippingRounded,
  LocationCityRounded,
  MapRounded,
  MenuBookRounded,
  MoneyRounded,
  MovieFilterRounded,
  NaturePeopleRounded,
  PhotoSizeSelectLargeRounded,
  PortraitRounded,
  SecurityRounded,
  SettingsRounded,
  ShoppingCartRounded,
  SupervisedUserCircleRounded,
  TimelapseRounded,
  TimelineTwoTone,
  TuneRounded,
  VerifiedUserRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { matchPath, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { setCurrentApp } from "src/slices/customers";
import { checkPermission } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import NavItem from "./NavItem";

const drawerWidth = 256;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const NAVICONS = {
  home: HomeRounded,
  layout: LocationCityRounded,
  clipboard: AssignmentRounded,
  briefcaseIcon: AccountBalanceRounded,
  shoppingCartIcon: AccountBalanceWalletRounded,
  support: ContactSupportRounded,
  civil: AssignmentIndRounded,
  map: MapRounded,
  cash: MoneyRounded,
  cartIcon: CommuteRounded,
  urban: DomainRounded,
  urbin: DomainRounded,
  adsIcon: MovieFilterRounded,
  eco: EnergySavingsLeafRounded,
  email: AlternateEmailRounded,
  bank: AtmRounded,
  localShipping: LocalShippingRounded,
  tuneIcon: TuneRounded,
  erp: GroupWorkRounded,
  archive: ArchiveRounded,
  menuBook: MenuBookRounded,
  groupWork: GroupWork,
  pollIcon: HowToVoteRounded,
  timelineTwoTone: TimelineTwoTone,
  input: InputRounded,
  timelapse: TimelapseRounded,
  security: SecurityRounded,
  barChart: BarChartRounded,
  libraryBooks: LibraryBooks,
  ads: GradientOutlined,
  photoSize: PhotoSizeSelectLargeRounded,
  shoppingCart: ShoppingCartRounded,
  developerBoard: BuildCircleRounded,
  socialResp: NaturePeopleRounded,
};
const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.primary.main,
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    color: theme.palette.primary.main,
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
    color: "white",
  },
  title: {
    marginRight: "auto",
    color: "rgba(255, 255, 255, 0.75) !important",
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.secondary.dark,
    },
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
    zIndex: 180,
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  adminMode: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    "& .MuiDrawer-paper": {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    color: `${theme.palette.secondary.contrastText} !important`,
    "& > .MuiButton > a": {
      root: {
        color: "white !important",
      },
    },
  },
}));

function renderNavItems({
  items,
  pathname,
  currentCustomer,
  admin,
  depth = 0,
}) {
  return (
    <List disablePadding>
      {items && items.length > 0
        ? items.reduce(
            (acc, item) =>
              reduceChildRoutes({
                acc,
                item,
                pathname,
                currentCustomer,
                depth,
                admin,
              }),
            []
          )
        : null}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  currentCustomer,
  admin,
}) {
  const key = item.title + depth;
  let base = "";
  if (currentCustomer) {
    base = `/${currentCustomer.shortname}`;

    // if (admin) {
    //   base = `${base}/`;
    // }
  }

  const href = base ? `${base}${item.href}` : item.href;
  if (item.items) {
    const open = matchPath(pathname, {
      path: href,
      exact: false,
    });
    if (!item.public && !admin) {
      // not display menu of not public modules
    } else {
      acc.push(
        <NavItem
          depth={depth}
          icon={NAVICONS[item.icon]}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
            currentCustomer: currentCustomer,
            admin: admin,
          })}
        </NavItem>
      );
    }
  } else {
    if (!item.public && !admin) {
      // not display menu of not public modules
    } else {
      if (item.title) {
        acc.push(
          <NavItem
            depth={depth}
            href={href}
            icon={NAVICONS[item.icon]}
            info={item.info}
            key={key}
            title={item.title}
          />
        );
      }
    }
  }

  return acc;
}

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customerName } = useParams();

  const settings = useSelector((state) => state.settings);

  const [adminSections, setAdminSections] = useState([]);
  const { customers, currentCustomer, customerSections, currentApp } =
    useSelector((state) => state.customers);
  const { sections, adminMode, hideNavbar } = settings;

  useCallback(() => {}, [sections]);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (currentCustomer && customerSections) {
      if (
        !currentApp ||
        !location.pathname.includes(
          `/${currentCustomer.shortname}/${currentApp.href}/`
        )
      ) {
        var cA = null;
        customerSections.forEach((_cS) => {
          if (
            location.pathname.includes(
              `/${currentCustomer.shortname}${_cS.href}`
            )
          ) {
            cA = _cS;
          }
        });
        dispatch(setCurrentApp(cA));
      }
    }
  }, [currentCustomer, customerSections, location.pathname, currentApp]);

  useEffect(() => {}, [sections, currentCustomer]);

  const UserSections = [
    {
      subheader: "Perfil",
      items: [
        {
          title: "Mi Portafolio",
          icon: Folder,
          color: "secondary",
          href: "/app/reports/dashboard",
          items: [
            {
              title: "Basic Data",
              href: "/app/account/general",
              icon: <VerifiedUserRounded />,
            },
            {
              title: "Identities",
              href: "/app/account/identities",
              icon: PortraitRounded,
            },
            {
              title: "Address",
              href: "/app/account/addresses",
              icon: MapRounded,
            },
            {
              title: "Companies",
              href: "/app/account/companies",
              icon: BuildRounded,
            },
            {
              title: "Vehicles",
              href: "/app/account/vehicles",
              icon: EmojiTransportationRounded,
            },
            // { title: 'RealEstates', href: '/app/account/vehicles', icon: Home },
          ],
        },
      ],
    },
  ];

  const loadAdminSections = () => {
    var _admSections = [];
    // if (
    //   checkPermission({
    //     action: "can_view_stats",
    //     module: "nets_customers",
    //     user: user,
    //     customerId: currentCustomer.id,
    //   })
    // ) {
    //   _admSections.push({
    //     title: t("Reportes"),
    //     icon: "barChart",
    //     href: `/AdminDashboard/`,
    //     target: "_bank",
    //   });
    // }
    _admSections.push({
      title: t("Configuración"),
      color: "secondary",
      icon: SettingsRounded,
      href: `/${currentCustomer.shortname}/corporateAdmin/`,
      items: [
        {
          title: t("Corporativo"),
          icon: SupervisedUserCircleRounded,
          color: "secondary",
          href: `/corporateAdmin/`,
        },
      ],
    });
    setAdminSections(_admSections);
  };

  useEffect(() => {
    if (
      user &&
      currentCustomer &&
      settings.adminMode &&
      checkPermission({
        action: "can_view_user",
        module: "nets_user",
        user: user,
        customerId: currentCustomer.id,
      })
    ) {
      loadAdminSections();
    }
  }, [currentCustomer, user, settings]);

  // useEffect(() => {
  //   if (!user) {
  //     dispatch(loadUserFromStorage());
  //   }
  // }, [user]);

  if (!user) {
    return null;
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {/* <Box p={2}>
            <List>
              <NavItem
                depth={0}
                icon={HomeRounded}
                title={t("Home")}
                href="/app/"
              />
            </List>
          </Box> 
          <Divider />*/}
          <Box mt={2} textAlign="center">
            <NavItem
              depth={0}
              href={`/app/account`}
              // link
              icon={() => (
                <Avatar
                  alt="User"
                  src={user.avatar}
                  style={{ marginRight: "8px" }}
                />
              )}
              key={"u-pro"}
              title={`${user.fullname}`}
              style={{ color: adminMode ? "white" : "green" }}
            />
            <NavItem
              depth={0}
              href={`/app/notify/inbox`}
              icon={() => <EmailRounded style={{ marginRight: "5px" }} />}
              title={t("Buzón de entrada")}
              info={
                user.notifications_count
                  ? () => (
                      <Badge
                        badgeContent={user.notifications_count}
                        color="secondary"
                      />
                    )
                  : null
              }
            />
            <NavItem
              depth={0}
              href={`/app/organizations/`}
              icon={() => <ListAltRounded style={{ marginRight: "5px" }} />}
              title={t("Mis organizaciones")}
              key="u-org"
            />
            {UserSections.map((section) => {
              return (
                <List key={section.subheader}>
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                  })}
                </List>
              );
            })}
          </Box>
        </Box>
        <Divider />

        <Box p={2}>
          {currentCustomer && customerSections.length > 0 && (
            <List disablePadding>
              <List disablePadding>
                {/* <ListItem>
                  <Button href={`/${currentCustomer.shortname}`}>
                    {currentCustomer.shortname.toLocaleUpperCase()}
                  </Button>
                </ListItem> */}
                <NavItem
                  depth={0}
                  icon={Home}
                  // icon={() => (
                  //   <Avatar
                  //     alt={currentCustomer.name}
                  //     src={media_url(currentCustomer.icon)}
                  //     style={{ paddingLeft: "-5px", marginRight: "6px" }}
                  //   />
                  // )}
                  href={`/${currentCustomer.shortname}`}
                  title={t("Inicio")}
                />
              </List>
              <List disablePadding>
                {adminSections.length > 0 &&
                  renderNavItems({
                    items: adminSections,
                    currentCustomer: currentCustomer,
                    admin: adminMode,
                  })}
                {renderNavItems({
                  items: customerSections,
                  pathname: location.pathname,
                  currentCustomer: currentCustomer,
                  admin: adminMode,
                })}
              </List>
            </List>
          )}
        </Box>

        {/* <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.capitalize}
            >
              {t('NeedHelp?')}
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="#"
              className={classes.capitalize}
            >
              {t('user support')}
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          className={adminMode ? classes.adminMode : null}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          className={adminMode ? classes.adminMode : null}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};
export { NAVICONS };
export default NavBar;
