import React, { useEffect } from "react";
import LottieAnimation from "src/components/LottieAnimation";
import useEnvironmentRequest from "src/hooks/useNetsEnvironment";
import animationData from "src/lotties/arkadu_city.json";
import { useSelector } from "src/store";

const EnvironmentLayout = ({ children }) => {
  const { environmentProducts } = useSelector((state) => state.environment);

  const { currentCustomer } = useSelector((state) => state.customers);
  const { adminMode } = useSelector((state) => state.settings);

  const { loadProducts } = useEnvironmentRequest(currentCustomer);
  useEffect(() => {
    if (!environmentProducts && currentCustomer) {
      console.log("loadProducts", currentCustomer);
      loadProducts();
    }
  }, [environmentProducts, currentCustomer]);
  if (!environmentProducts) {
    return <LottieAnimation animationData={animationData} size={"400px;"} />;
  }
  return <>{children}</>;
};

export default EnvironmentLayout;
